import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import MqttEvent from "./MqttEvent";
import MqttCommand from "./MqttCommand";

class Events extends PureComponent {
  render() {
    const { events } = this.props;

    return (
      <ul className="list-group">
        {events.map(event => {
          return event.payload ? (
            <MqttEvent key={event.id} event={event} />
          ) : (
            <MqttCommand key={event.id} command={event} />
          );
        })}
      </ul>
    );
  }
}

Events.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      payload: PropTypes.shape({})
    })
  ),
  mqttCommands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      command: PropTypes.shape({})
    })
  )
};

const compare = (a, b) => {
  return moment(a.created_at).unix() > moment(b.created_at).unix() ? -1 : 1;
};

const events = printer => {
  return printer.events.slice().concat(printer.mqtt_commands.slice()).sort(compare);
};

const mapStateToProps = state => ({
  events: events(state.printerReducer.printer)
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Events);
