import $ from 'jquery';
import Chart from 'chart.js';

class ChartCreator {
  constructor(chartName, chartData, chartContainer) {
    this.chartName = chartName;
    this.chartData = chartData;
    this.chartContainer = chartContainer;
  }

  load() {
    const $originalCanvas = this.chartContainer.find( 'canvas' );
    const originalId = $originalCanvas.attr( 'id' );
    const originalHeight = $originalCanvas.attr( 'height' );
    const originalWidth = $originalCanvas.attr( 'width' );

    $originalCanvas.remove();
    this.chartContainer.prepend(`<canvas id='${originalId}' data-chart-name='${this.chartName}' height='${originalHeight}' width='${originalWidth}'></canvas>`);

    return $.ajax({
      data: this.chartData,
      url: `/chart/${this.chartName}.json`,
      method: 'GET'
    }).done(data => {
      const $canvas = this.chartContainer.find( 'canvas' );
      const width = this.chartContainer.width();
      const context = $canvas.get(0).getContext( '2d' );
      const options = {
        responsive: true,
      };

      $canvas.width(width);
      return new Chart(context, {
        type: this.chartData.type,
        data,
        options,
      });
    });
  }
}

export default ChartCreator;
