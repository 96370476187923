import $ from 'jquery';

$(() => {
  if ($( 'body.admin-users' ).length > 0) {
    $( 'ul.nav li a' ).removeClass( 'active' );
    $( 'li.users a' ).addClass( 'active' );

    if ($( 'body.admin-users-index' ).length > 0) {
      $( document ).on( 'click', '#toolbar button.btn', (event) => {
        const $element = $( event.target )
          .closest( 'button' );

        window.location = $element[0].dataset.url;
      });

      return $( 'table.users' ).on( 'click-row.bs.table', (element, row) => {
        const user_id = row.id;
        const url = `/admin/users/${user_id}`;

        window.location = url;

        return null;
      });
    }

    if ($( 'body.admin-users-show' ).length > 0) {
      $( document ).on( 'click', 'button.edit', (event) => {
        const $element = $( event.target )
          .closest( 'button' );

        window.location = $element[0].dataset.url;
      });
    }
  }
});
