import $ from 'jquery';

import { resolutionDefault, resolutionLabels } from '../../shared/constants';
import { loadClientsJobsLineChart } from '../../shared/charts';

$(() => {
  if ($( 'body.admin-home' ).length > 0) {
    $( 'ul.nav li a' ).removeClass( 'active' );
    $( 'li.home a' ).addClass( 'active' );

    if ($( 'body.admin-home-show' ).length > 0) {
      $( document ).on( 'click', '.dashhead a.dropdown-item', (event) => {
        const resolutionData = $( event.target ).data( 'resolution' );
        const resolutionLabel = resolutionLabels[resolutionData];
        const iconHtml = '<span class="icon icon-calendar"></span> ';

        $( '.dashhead a.dropdown-item' )
          .removeClass( 'active' )
        $( '.dashhead a.dropdown-item[data-resolution="' + resolutionData + '"]' )
          .addClass( 'active' );
        $( '#resolution' )
          .html( iconHtml + resolutionLabel );

        loadClientsJobsLineChart(resolutionData);
      });

      $( '.dashhead a.dropdown-item[data-resolution="' + resolutionDefault + '"]' )
        .addClass( 'active' );

      loadClientsJobsLineChart(resolutionDefault);

      return null;
    }
  }
});
