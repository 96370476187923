import $ from 'jquery';

$(() => {
  if ($( 'body.admin-sims' ).length > 0) {
    $( 'ul.nav li a' ).removeClass( 'active' );
    $( 'li.sims a' ).addClass( 'active' );

    if ($( 'body.admin-sims-index' ).length > 0) {
      $( document ).on( 'click', '#toolbar button.btn', (event) => {
        const $element = $( event.target )
          .closest( 'button' );

        window.location = $element[0].dataset.url;
      });
    }

    if ($( 'body.admin-sims-show' ).length > 0) {
      $( document ).on( 'click', 'button.edit', (event) => {
        const $element = $( event.target )
          .closest( 'button' );

        window.location = $element[0].dataset.url;
      });
    }

    return null;
  }
});
