import $ from 'jquery';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import moment from 'moment';

import createChannel from '../../shared/cable';
import { contextFinder } from '../../shared/finders';

$(() => {
  if ($( 'body.manager-jobs' ).length > 0) {
    $( 'ul.nav li a' ).removeClass( 'active' );
    $( 'li.jobs a' ).addClass( 'active' );

    if ($( 'body.manager-jobs-index' ).length > 0) {
      createChannel( { channel: 'Manager::JobsChannel' }, {
        received({ message }) {
          let method, row_index;
          const $table = $( 'table.jobs' );
          const bs_data = $table.bootstrapTable( 'getData' );

          if (filter(bs_data, { id: parseInt(message.id) }).length > 0) {
            method = 'updateRow';
            row_index = findIndex(bs_data, {id: parseInt(message.id)});
          } else {
            method = 'insertRow';
            row_index = 0;
          }

          $table.bootstrapTable(method, { index: row_index, row: message.attributes });

          return null;
        }
      })
    }

    if ($( 'body.manager-jobs-show' ).length > 0) {
      const jobId = $( '.container' ).data( 'object-id' );
      const context = contextFinder();

      $( 'button#reprint' ).on( 'click', (event) => {
        event.preventDefault();

        $.ajax({
          method: 'POST',
          url: '/manager/job_reprint',
          data: { job_id: jobId },
        }).done(() => {
          $( '#reprintModal' ).modal( 'hide' );
          $( '#stateLabel' ).removeClass().addClass( 'label upcase label-info' ).text( 'queued' );
          $( 'dd.printed_at, dd.expired_at' ).text( 'n/a' );
          $( '#actionReprint' ).prop( 'disabled', true).removeClass( 'btn-success-outline' ).addClass( 'btn-default-outline' );

          return null;
        });

        return null;
      });

      $( 'button#report' ).on( 'click', (event) => {
        event.preventDefault();

        const job_id = event.target.dataset.job_id;
        const flag_id = $( '#job_flag_flag_id' ).val();
        const data = $( '#job_flag_data' ).val();

        $.ajax({
          method: 'POST',
          url: "/manager/job_flags.json",
          data: { job_id, flag_id, data },
        }).done(() => {
          $( '#reportModal' ).modal( 'hide' );
          $( 'input#job_flag_job_id' ).val(null);
          $( 'input#job_flag_data' ).val(null);
          $( 'button#report' ).removeClass( 'btn-success-outline' ).addClass( 'disabled btn-default-outline' ).prop({disabled: true});
          $( '.flag-data' ).addClass( 'has-error' ).removeClass( 'has-success' );

          return null;
        });

        return null;
      });

      $( '#job_flag_flag_id' ).on( 'change', (event) => {
        const value = event.target.value;

        $( '.flag-data' ).removeClass( 'hidden' ).find( 'input' ).focus();
        $( 'input#job_flag_data' ).val(null).keyup();

        if (!value) {
          $( '.flag-data' ).addClass( 'hidden' );

          return null;
        }

        return null;
      });

      $( '#job_flag_data' ).on( 'keyup', (e) => {
        const value = e.target.value;

        if (value > 0) {
          $( '.flag-data' ).removeClass( 'has-error' ).addClass( 'has-success' );
          $( 'button#report' ).removeClass( 'btn-default-outline disabled' ).addClass( 'btn-success-outline' ).prop({disabled: false});

          return null;
        } else {
          $( '.flag-data' ).addClass( 'has-error' ).removeClass( 'has-success' );
          $( 'button#report' ).removeClass( 'btn-success-outline' ).addClass( 'disabled btn-default-outline' ).prop({disabled: true});

          return null;
        }
      });

      createChannel( { channel: 'Manager::JobChannel', id: jobId }, {
        received({ message }) {
          let expired_at, printed_at;
          let label = (() => { switch (message.attributes.state) {
            case 'queued': return 'info';
            case 'downloaded': return 'info';
            case 'printed': return 'success';
            case 'expired': return 'danger';
            default: return 'info';
          } })();

          if ((message.attributes.state === 'printed') && message.attributes.late_print) {
            label = 'warning';
          }

          if (message.attributes.printed_at) {
            printed_at = moment(message.attributes.printed_at).tz(context.current_user.time_zone).format('YYYY-MM-DD HH:mm:ss z');
          } else {
            expired_at = 'n/a';
          }

          if (message.attributes.expired_at) {
            expired_at = moment(message.attributes.expired_at).tz(context.current_user.time_zone).format('YYYY-MM-DD HH:mm:ss z');
          } else {
            expired_at = 'n/a';
          }

          if (message.relationships.flags.data.length > 0) {
            const insertFlag = flag => $( '#flags ul' ).append( `<li class='text-danger'>${flag.description}</li>` );

            $( '#flags' ).removeClass().find( 'ul' ).empty();
            for (let flag of Array.from(message.relationships.flags.data)) { insertFlag(flag); }
          }

          if ((message.attributes.state === 'expired') || (message.attributes.state === 'printed')) {
            $( '#actionReprint' ).removeAttr( 'disabled' ).removeClass().addClass( 'btn btn-lg btn-block btn-success-outline' );
          } else {
            $( '#actionReprint' ).attr( 'disabled', true ).removeClass().addClass( 'btn btn-lg btn-block btn-default-outline' );
          }

          $( 'label' ).removeClass().addClass( `label label-${label} upcase` ).text(message.attributes.state);
          $( 'dd.printed_at' ).text(printed_at);
          $( 'dd.expired_at' ).text(expired_at);

          return null;
        }
      });

      return null;
    }
  }
});
