import $ from 'jquery';

$(() => {
  if ($( 'body.manager-client_printers' ).length > 0) {
    $( 'ul.nav li a' ).removeClass( 'active' );
    $( 'li.printers a' ).addClass( 'active' );

    return null;
  }
});
