import actionTypes from "../actions/actionTypes";

const initialState = { printer: null };

export function printerReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.PRINTER_SET_PRINTER: {
      const { printer } = action.payload;

      return { ...state, printer };
    }
    default:
      return state;
  }
}
