import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";

const customStyles = {
  option: provided => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: "#333333",
    padding: 20
  })
};

const fetchSims = inputValue => {
  if (inputValue.length < 5) {
    return [];
  }

  return fetch(`/admin/sims/search.json?search=${inputValue}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" }
  })
    .then(response => response.json())
    .then(json => {
      return json.data.map(sim => {
        return { label: sim.iccid, value: sim.id };
      });
    })
    .catch(error => console.log(error));
};

const promiseOptions = inputValue =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(fetchSims(inputValue));
    }, 1000);
  });

const defaultOptions = printer => {
  if (printer.sim.id) {
    return [{ label: printer.sim.iccid, value: printer.sim.id }];
  }

  return [];
};

class RemoteSelect extends Component {
  render() {
    const { printer } = this.props;

    return (
      <AsyncSelect
        backspaceRemovesValue={true}
        defaultValue={defaultOptions(printer)[0]}
        defaultOptions={defaultOptions(printer)}
        inputId={"printer_sim_id"}
        isClearable={true}
        loadOptions={promiseOptions}
        name={"printer[sim_id]"}
        placeholder={printer.sim.iccid || "Select..."}
        styles={customStyles}
      />
    );
  }
}

RemoteSelect.propTypes = {
  printer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sim: PropTypes.shape({
      id: PropTypes.number,
      iccid: PropTypes.string
    })
  }).isRequired
};

export default RemoteSelect;
