import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import configureStore from "../configureStore";

import Printer from "./Printer";

const store = configureStore();

const App = props => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="*" render={() => <Printer {...props} />} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
};

App.propTypes = {
  printerId: PropTypes.number.isRequired
};

export default App;
