const resolutionDefault = '72-hour';

const resolutionLabels = {
  '12-month': 'Last 12 Months',
  '90-day': 'Last 90 days',
  '60-day': 'Last 60 days',
  '30-day': 'Last 30 days',
  '7-day': 'Last 7 days',
  [resolutionDefault]: 'Last 72 hours',
  '48-hour': 'Last 48 hours',
  '24-hour': 'Last 24 hours',
  '12-hour': 'Last 12 hours',
  '6-hour': 'Last 6 hours',
  '3-hour': 'Last 3 hours',
};

export { resolutionDefault, resolutionLabels };
