const mqttEvents = {
  clientConnected: "client.connected",
  clientDisconnected: "client.disconnected",
  configUpdated: "Config.Updated",
  jobDownloaded: "Job.Downloaded",
  jobEdited: "Job.Edited",
  jobReprint: "Job.Reprint",
  jobPrinted: "Job.Printed",
  jobRejected: "Job.Rejected",
  printerConnected: "Printer.Connected",
  printerPaper: "Printer.Paper",
  printerReset: "Printer.Reset",
};

const mqttEventsDisplay = {
  "client.connected": "MQTT Connected",
  "client.disconnected": "MQTT Disconnected",
  "Config.Updated": "Config Updated",
  "Job.Downloaded": "Job Downloaded",
  "Job.Edited": "Job Edited",
  "Job.Printed": "Job Printed",
  "Job.Rejected": "Job Rejected",
  "Job.Reprint": "Job Reprinted",
  "Printer.Connected": "Printer Connected",
  "Printer.Paper": "Printer Paper",
  "Printer.Reset": "Printer Reset",
};

export { mqttEvents, mqttEventsDisplay };
