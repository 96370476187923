import $ from 'jquery';


import ChartCreator from './ChartCreator';
import {
  resolutionFinder ,
  typeFinder,
  printerIdFinder,
} from '../finders';

const loadClientJobsLineChart = (resolution) => {
  const $chartContainer = $( '.chart-container' );
  const clientId = $chartContainer[0].dataset.client_id;
  const chartName = 'ClientJobs';
  const chartData = {
    type: 'line',
    resolution,
    object_id: clientId,
  };
  const chart = new ChartCreator(chartName, chartData, $chartContainer);

  return chart.load();
};

const loadAllCommandsStatusChart = () => {
  let chartName;
  const resolution = resolutionFinder();
  const type = typeFinder();
  const chartData = {
    type: 'line',
    resolution
  };
  const chartContainer = $( '.chart.main' );

  switch (type) {
    case 'Status':
      chartName = 'AllCommandsStatus';
      break;
    case 'Timing':
      chartName = 'AllCommandsTiming';
      break;
    default:
      chartName = 'AllCommandsStatus';
  }

  const chart = new ChartCreator(chartName, chartData, chartContainer);

  return chart.load();
};

const loadClientsJobsLineChart = (resolution) => {
  const chartName = 'AllJobsState';
  const chartData = {
    type: 'line',
    resolution
  };
  const chartContainer = $( '.chart-container' );
  const chart = new ChartCreator(chartName, chartData, chartContainer);

  return chart.load();
};

const loadPrinterLineChart = (resolution) => {
  const printerId = printerIdFinder();
  const chartName = 'PrinterJobs';
  const chartData = {
    type: 'line',
    resolution,
    object_id: printerId,
  };
  const chartContainer = $( '.chart-container' );
  const chart = new ChartCreator(chartName, chartData, chartContainer);

  return chart.load();
};

const loadPrinterAveragePrintTimeTextChart = () => {
  const printer_id = printerIdFinder();
  return $.ajax({
    url: '/chart/PrinterAveragePrintTime.json',
    data: {
      type: 'text',
      object_id: printer_id
    },
    method: 'GET'
  }).done(data => $( '.statcard-number.print-time' ).text(data.time));
};

const loadPrinterAverageDownloadTimeTextChart = () => {
  const printer_id = printerIdFinder();
  return $.ajax({
    url: '/chart/PrinterAverageDownloadTime.json',
    data: {
      type: 'text',
      object_id: printer_id
    },
    method: 'GET'
  }).done(data => $( '.statcard-number.delivery-time' ).text(data.time));
};

export {
  loadClientJobsLineChart,
  loadAllCommandsStatusChart,
  loadClientsJobsLineChart,
  loadPrinterLineChart,
  loadPrinterAveragePrintTimeTextChart,
  loadPrinterAverageDownloadTimeTextChart,
};
