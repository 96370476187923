import $ from 'jquery';

import createChannel from '../../shared/cable';

$(() => {
  if ($( 'body.admin-jobs' ).length > 0) {
    $( 'ul.nav li a' ).removeClass( 'active' );
    $( 'li.jobs a' ).addClass( 'active' );

    if ($( 'body.admin-jobs-index' ).length > 0) {
      $( document ).on( 'click', '#toolbar button.btn', (event) => {
        const $element = $( event.target )
          .closest( 'button' );
        const mode = $element[0].dataset.mode;

        if (mode === 'play') {
          $element.attr( 'data-mode', 'pause' )
            .addClass( 'btn-danger' )
            .removeClass( 'btn-primary' );
          $element
            .find( 'span.icon' )
              .addClass( 'icon-controller-paus' )
              .removeClass( 'icon-controller-play' );
          $element
            .find( 'span.content' )
            .text( 'Paused' )
        } else {
          $element.attr( 'data-mode', 'play' )
            .addClass( 'btn-primary' )
            .removeClass( 'btn-danger' );
          $element
            .find( 'span.icon' )
              .addClass( 'icon-controller-play' )
              .removeClass( 'icon-controller-paus' );
          $element
            .find( 'span.content' )
            .text( 'Live' )
        }
      });

      createChannel( 'Admin::JobsChannel', {
        received(message) {
          if ($( '#toolbar button.btn' )[0].dataset.mode === 'pause' ) {
            return null;
          }

          const $table = $( 'table.jobs' );
          const data = $table.bootstrapTable( 'getData' );
          const job = data.find((row) => row.id === message.id);

          if (job) {
            const index = data.findIndex((row) => row.id === message.id);

            $table.bootstrapTable('updateRow', { index, row: message, replace: true });
          } else {
            const pageSize = $table.bootstrapTable( 'getOptions' ).pageSize - 1 || 24;
            const row = data[pageSize];

            $table.bootstrapTable('insertRow', { index: 0, row: message });
            $table.bootstrapTable('remove', { field: 'id', values: [row.id] });
          }

          return null;
        }
      });

      return null;
    }
  }
});
