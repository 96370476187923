import $ from "jquery";

const commandCommandableStatus = value => {
  switch (value) {
    case "delivered": {
      return { css: { "background-color": "#1BC98E" } };
    }
    case "failed": {
      return { css: { "background-color": "#E64759" } };
    }
    default: {
      return { css: { "background-color": "#E4D836" } };
    }
  }
};

const commandCommandableTwilioLink = value => {
  const url = `https://www.twilio.com/console/wireless/commands/${value}`;

  return `<a href="${url}">${value}</a>`;
};

const booleanCellStyle = value => {
  return value
    ? { css: { "background-color": "#1BC98E" } }
    : { css: { "background-color": "#E64759" } };
};

const jobStateCellStyle = (value, row) => {
  switch (value) {
    case "queued": {
      return { css: { "background-color": "#9F86FF" } };
    }
    case "printed": {
      if (row.late_print) {
        return { css: { "background-color": "#E4D836" } };
      } else {
        return { css: { "background-color": "#1BC98E" } };
      }
    }
    case "expired": {
      return { css: { "background-color": "#E64759" } };
    }
    default: {
      return { css: { "background-color": "#9F86FF" } };
    }
  }
};

const commandCommandableLink = (value, row) => {
  const { commandable_type } = row.attributes;
  const { commandable_id } = row.attributes;

  return `<a href='/admin/${commandable_type.toLowerCase()}s/${commandable_id}'>${commandable_type}</a>`;
};

const usersClientIdFormatter = (value, row) => {
  const { client_id } = row;

  if (value) {
    return `<a href='/admin/clients/${client_id}'>${value}</a>`;
  } else {
    return "n/a";
  }
};

const usersUserIdFormatter = value => {
  if (value) {
    return `<a href='/admin/users/${value}'>${value}</a>`;
  } else {
    return "n/a";
  }
};

const jobsClientIdFormatter = (value, row) => {
  const { client_id } = row;

  if (value) {
    return `<a href='/admin/clients/${client_id}'>${value}</a>`;
  } else {
    return "n/a";
  }
};

const clientsClientIdFormatter = (value, row) => {
  const { id } = row;

  if (value) {
    return `<a href='/admin/clients/${id}'>${value}</a>`;
  } else {
    return "n/a";
  }
};

const printerApiIdFormatter = (value, row) => {
  const { printer_id } = row;

  if (value) {
    let namespace = "manager";

    if (
      $("body")
        .attr("class")
        .search(/admin-/) > -1
    ) {
      namespace = "admin";
    }

    return `<a href='/${namespace}/printers/${printer_id}'>${value}</a>`;
  } else {
    return "n/a";
  }
};

const printerNameFormatter = (value, row) => {
  const { printer_id } = row;

  if (value) {
    let namespace = "manager";

    if (
      $("body")
        .attr("class")
        .search(/admin-/) > -1
    ) {
      namespace = "admin";
    }

    return `<a href='/${namespace}/printers/${printer_id}'>${value}</a>`;
  } else {
    return "n/a";
  }
};

const jobExternalIdFormatter = (value, row) => {
  let external_id;
  const job_id = row.id;
  let namespace = "manager";

  if (value) {
    external_id =
      value.length > 10
        ? `...${value.slice(value.length - 10, value.length)}`
        : value;
  } else {
    external_id = "n/a";
  }

  if (
    $("body")
      .attr("class")
      .search(/admin-/) > -1
  ) {
    namespace = "admin";
  }

  return `<a href='/${namespace}/jobs/${job_id}'>${external_id}</a>`;
};

const commandStatusFormatter = value => {
  let label;
  switch (value) {
    case "queued":
    case "sent":
    case "received":
    case "delivered":
      label = "label-warning";
      break;
    case "failed":
      label = "label-danger";
      break;
    default:
      label = "label-info";
  }

  return `<span class='label ${label}'>${value}</span>`;
};

const jobStateFormatter = (value, row) => {
  let badge;

  if (!value) {
    return null;
  }

  switch (value.trim()) {
    case "printed":
      if (row && row.late_print) {
        badge = "badge-warning";
      } else {
        badge = "badge-success";
      }
      break;
    case "expired":
      badge = "badge-danger";
      break;
    default:
      badge = "badge-info";
  }

  return `<span class='badge ${badge}'>${value.toUpperCase()}</span>`;
};

const printerStateFormatter = value => {
  let label;
  if (value.trim() === "available") {
    label = "label-success";
  } else {
    label = "label-danger";
  }

  return `<span class='label ${label}'>${value}</span>`;
};

const printerPaperStatusFormatter = value => {
  let label;
  if (value) {
    label = "label-success";
  } else {
    label = "label-danger";
  }

  return `<span class='label ${label}'>${value}</span>`;
};

const booleanFormatter = value => {
  let label, text;
  if (value) {
    label = "label-success";
    text = "TRUE";
  } else {
    label = "label-danger";
    text = "FALSE";
  }

  return `<span class='label ${label}'>${text}</span>`;
};

const printerTypeFormatter = value => {
  return value === "wifi"
    ? '<span class="icon icon-signal"></span>'
    : '<span class="icon icon-old-mobile"></span>';
};

const simIdFormatter = (value, row) => {
  const sim_id = row.sim_id;

  return value
    ? "<a href='/admin/sims/" + sim_id + "'>" + value + "</a>"
    : "n/a";
};

const simSidFormatter = value => {
  return value
    ? "<a href='https://www.twilio.com/console/wireless/sims/" +
        value +
        "'>" +
        value +
        "</a>"
    : "n/a";
};

export {
  commandCommandableStatus,
  commandCommandableTwilioLink,
  booleanCellStyle,
  jobStateCellStyle,
  commandCommandableLink,
  jobsClientIdFormatter,
  clientsClientIdFormatter,
  usersClientIdFormatter,
  printerApiIdFormatter,
  printerNameFormatter,
  jobExternalIdFormatter,
  commandStatusFormatter,
  jobStateFormatter,
  printerStateFormatter,
  printerPaperStatusFormatter,
  booleanFormatter,
  printerTypeFormatter,
  simIdFormatter,
  simSidFormatter,
  usersUserIdFormatter
};
