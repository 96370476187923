import $ from 'jquery';

$(() => {
  if ($( 'body.admin-printers' ).length > 0) {
    $( 'ul.nav li a' ).removeClass( 'active' );
    $( 'li.printers a' ).addClass( 'active' );

    if ($( 'body.admin-printers-index' ).length > 0) {
      $( document ).on( 'click', '#toolbar button.btn', (event) => {
        const $element = $( event.target )
          .closest( 'button' );

        window.location = $element[0].dataset.url;
      });
    }
  }
});
