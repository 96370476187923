import $ from 'jquery';

import { updatePrinterAttributes } from '../../shared/printer';
import createChannel from '../../shared/cable';

$(() => {
  if ($( 'body.manager-client_printers' ).length > 0) {
    $( 'ul.nav li a' ).removeClass( 'active' );
    $( 'li.printers a' ).addClass( 'active' );

    if ($( 'body.manager-client_printers-show' ).length > 0) {
      const clientPrinterId = $('.container').data('object-id');

      $( 'button.online' ).on( 'click', () => {
        if ($( this ).hasClass( 'active' )) { return null; }

        const printerId = $( 'button.online' ).data( 'printer-id' );

        $.ajax({
          method: 'PUT',
          url: `/manager/client_printers/${printerId}.json`,
          data: { client_printer: { enabled: true } },
        }).done(data => {
            updatePrinterAttributes(data);

            return null;
        });
      });

      $( 'button.offline' ).on( 'click', () => {
        if ($( this ).hasClass( 'active' )) { return null; }

        const printerId = $( 'button.offline' ).data( 'printer-id' );

        $.ajax({
          method: 'PUT',
          url: `/manager/client_printers/${printerId}.json`,
          data: { client_printer: { enabled: false } },
        }).done(data => {
            updatePrinterAttributes(data);

            return null;
        });
      });

      createChannel( { channel: 'Manager::ClientPrinterChannel', room: clientPrinterId }, {
        received({ message }) {
          updatePrinterAttributes(message);

          return null;
        }
      });

      return null;
    }
  }
});
