import $ from 'jquery';
import each from 'lodash/each';
import booleanLabelFormatter from '../../shared/formatters/booleanLabelFormatter';

const formatSoundLightValue = (value) => {
  switch(value) {
    case 0: {
      return 'Off';
    }
    case 1: {
      return 'Medium';
    }
    case 2: {
      return 'High';
    }
  }
}

const updatePrinterAttributes = (data) => {
  each(
    data.data.attributes,
    (value, key) => $( `dd.${key}` ).text(value));
  if (data.data.attributes.enabled) {
    $( 'button.online' )
      .addClass( 'active btn-success' )
      .removeClass( 'btn-default' );
    $( 'button.offline' )
      .addClass( 'btn-default' )
      .removeClass( 'active btn-danger' );
  } else {
    $( 'button.offline' )
      .addClass( 'active btn-danger' )
      .removeClass( 'btn-default' );
    $( 'button.online' )
      .addClass( 'btn-default' )
      .removeClass( 'active btn-success' );
  }
  for (let element of Array.from($( '.boolean' ))) { booleanLabelFormatter(element); }
  $( 'dd.sound' ).text(formatSoundLightValue(data.data.attributes.sound));
  $( 'dd.light' ).text(formatSoundLightValue(data.data.attributes.light));

  return null;
};

export { formatSoundLightValue, updatePrinterAttributes };
