import $ from "jquery";
import Chart from "chart.js";

import booleanLabelFormatter from "./shared/formatters/booleanLabelFormatter";

Chart.defaults.global.responsive = true;

for (let element of Array.from($(".boolean"))) {
  booleanLabelFormatter(element);
}

$(document).on("click", ".icon-copy", event => {
  const text = $(event.target).data("clipboard-text");

  navigator.clipboard.writeText(text);
});
