/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs";
import ReactRailsUJS from "react_ujs";

import "jquery";
import "bootstrap-table";
import "popper.js";

import "src/vendor/toolkit";

import "src/application";

import "src/shared/printer";
import "src/shared/finders";
import "src/shared/modal";
import "src/shared/cable";

import "src/admin/home";
import "src/admin/jobs";
import "src/admin/printers";
import "src/admin/printers/show";
import "src/admin/sims";
import "src/admin/clients";
import "src/admin/commands";
import "src/admin/users";

import "src/manager/jobs";
import "src/manager/client_printer";
import "src/manager/client_printers";

import * as bootstrapTableFormatters from "src/shared/formatters/bootstrapTableFormatters";

Rails.start();

const componentRequireContext = require.context("../admin/components", true);

ReactRailsUJS.useContext(componentRequireContext);
window.bootstrapTableFormatters = bootstrapTableFormatters;
