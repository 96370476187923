import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { mqttCommandsDisplay } from "../constants";

class MqttCommand extends PureComponent {
  render() {
    const { command } = this.props;

    return (
      <li className="list-group-item d-flex justify-content-between align-items-center">
        <span className="btn btn-xs btn-pill btn-secondary">
          {command.command}
        </span>
        <span>
          {moment(command.created_at).format("dddd, MMMM Do YYYY, h:mm:ss A")}
        </span>
      </li>
    );
  }
}

MqttCommand.propTypes = {
  command: PropTypes.shape({
    id: PropTypes.number,
    command: PropTypes.string,
    created_at: PropTypes.string
  })
};

export default MqttCommand;
