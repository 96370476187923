import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { mqttEvents, mqttEventsDisplay } from "../constants";

class MqttEvent extends PureComponent {
  render() {
    let additionalClasses = {};
    const { event } = this.props;

    const eventName = event.payload.payload
      ? event.payload.payload.event
      : event.payload.event;

    switch (eventName) {
      case mqttEvents.clientConnected:
      case mqttEvents.printerConnected: {
        additionalClasses = "btn-success";

        break;
      }
      case mqttEvents.clientDisconnected:
      case mqttEvents.jobRejected:
      case mqttEvents.printerReset: {
        additionalClasses = "btn-danger";

        break;
      }
      case mqttEvents.configUpdated:
      case mqttEvents.jobReprint:
      case mqttEvents.printerPaper: {
        additionalClasses = "btn-warning";

        break;
      }
      default: {
        additionalClasses = "btn-info";
      }
    }

    return (
      <li className="list-group-item d-flex justify-content-between align-items-center">
        <span className={`btn btn-xs btn-pill ${additionalClasses}`}>
          {mqttEventsDisplay[eventName]}
        </span>
        <span>
          {moment(event.created_at).format("dddd, MMMM Do YYYY, h:mm:ss A")}
        </span>
      </li>
    );
  }
}

MqttEvent.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    payload: PropTypes.shape({
      event: PropTypes.string,
      payload: PropTypes.shape({
        event: PropTypes.string
      })
    }),
    created_at: PropTypes.string
  })
};

export default MqttEvent;
