import actionTypes from "./actionTypes";

const getPrinter = id => {
  return dispatch => {
    return fetch(`/admin/printers/${id}.json`, {
      method: "GET",
      headers: { "Content-Type": "application/json" }
    })
      .then(response => response.json())
      .then(json => {
        dispatch({
          type: actionTypes.PRINTER_SET_PRINTER,
          payload: { printer: json.printer }
        });
      })
      .catch(error => console.log(error));
  };
};

export { getPrinter };
