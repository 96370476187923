import $ from 'jquery';

const clientIdFinder = () => {
  return $( '.chart.main' ).data( 'client-id' );
}

const resolutionFinder = () => {
  return $( '#graph-resolution' ).val();
}

const typeFinder = () => {
  return $( '#graph-type' ).val();
}

const printerIdFinder = () => {
  return $( '.container' ).data( 'object-id' );
}

const contextFinder = () => {
  return $( 'div#context' ).data( 'current-user' );
}

export {
  clientIdFinder,
  resolutionFinder,
  typeFinder,
  printerIdFinder,
  contextFinder,
};
