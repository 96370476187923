import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Events from "./Events";
import { getPrinter } from "../actions/actions";

class Printer extends PureComponent {
  componentDidMount() {
    const { onLoad, printerId } = this.props;

    onLoad(printerId);
  }

  render() {
    const { printer } = this.props;

    if (printer === null) {
      return null;
    }

    return (
      <div className="row events">
        <div className="col-md-12">
          <h5>
            <span className="icon icon-rss"></span>
            Events (last 24 hours)
          </h5>
          <hr className="mt-3 mb-3" />
          <Events />
        </div>
      </div>
    );
  }
}

Printer.propTypes = {
  onLoad: PropTypes.func.isRequired,
  printer: PropTypes.shape({}),
  printerId: PropTypes.number.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  printer: state.printerReducer.printer,
  printerId: ownProps.printerId
});

const mapDispatchToProps = dispatch => ({
  onLoad: printerId => {
    dispatch(getPrinter(printerId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Printer);
