import $ from "jquery";
import last from "lodash/last";
import filter from "lodash/filter";
import findIndex from "lodash/findIndex";

import { resolutionDefault, resolutionLabels } from "./constants";
import { loadPrinterLineChart } from "../../shared/charts";
import { printerIdFinder } from "../../shared/finders";
import createChannel from "../../shared/cable";

$(() => {
  if ($("body.admin-printers").length > 0) {
    $("ul.nav li a").removeClass("active");
    $("li.printers a").addClass("active");

    if ($("body.admin-printers-show").length > 0) {
      const printerId = printerIdFinder();

      $(document).one("click", "button.edit", event => {
        const $element = $(event.target).closest("button");

        window.location = $element[0].dataset.url;
      });

      $(document).one("click", "button.config-set", event => {
        let data = { printer_id: printerId };
        const $element = $(event.target).closest("button");
        const url = $element[0].dataset.url;

        if (url === "/admin/commands") {
          data.command = $element[0].dataset.command;
        }

        $element.addClass("btn-warning").removeClass("btn-info");
        $element
          .find("span.icon")
          .addClass("icon-circular-graph icon-spin")
          .removeClass("icon-sound-mix");
        $.ajax({
          url: url,
          data: {
            data: data,
            type: "json"
          },
          method: "POST"
        }).done(
          $element.addClass("btn-success disabled").removeClass("btn-danger"),
          $element
            .find("span.icon")
            .addClass("icon-thumbs-up")
            .removeClass("icon-circular-graph icon-spin")
        );

        return null;
      });

      $(document).one("click", "button.create-user", event => {
        const $element = $(event.target).closest("button");
        const url = $element[0].dataset.url;
        let data = {
          printer_id: printerId,
          command: $element[0].dataset.command
        };

        $element.addClass("btn-warning").removeClass("btn-info");
        $element
          .find("span.icon")
          .addClass("icon-circular-graph icon-spin")
          .removeClass("icon-v-card");
        $.ajax({
          url: url,
          data: {
            data: data,
            type: "json"
          },
          method: "POST"
        }).done(
          $element.addClass("btn-success disabled").removeClass("btn-danger"),
          $element
            .find("span.icon")
            .addClass("icon-v-card")
            .removeClass("icon-circular-graph icon-spin")
        );

        return null;
      });

      $(document).one("click", "button.create-test-job", event => {
        let data = { printer_id: printerId };
        const $element = $(event.target).closest("button");
        const url = $element[0].dataset.url;

        if (url === "/admin/commands") {
          data.command = $element[0].dataset.command;
        }

        $element.addClass("btn-warning").removeClass("btn-info");
        $element
          .find("span.icon")
          .addClass("icon-circular-graph icon-spin")
          .removeClass("icon-flash");
        $.ajax({
          url: url,
          data: {
            type: "json",
            data: data
          },
          method: "POST"
        }).done(
          $element.addClass("btn-success disabled").removeClass("btn-danger"),
          $element
            .find("span.icon")
            .addClass("icon-thumbs-up")
            .removeClass("icon-circular-graph icon-spin")
        );

        return null;
      });

      $(document).one("click", "button.sys-get-info", event => {
        let data = { printer_id: printerId };
        const $element = $(event.target).closest("button");
        const url = $element[0].dataset.url;
        data.command = $element[0].dataset.command;

        $element.addClass("btn-warning").removeClass("btn-info");
        $element
          .find("span.icon")
          .addClass("icon-circular-graph icon-spin")
          .removeClass("icon-flash");
        $.ajax({
          url: url,
          data: {
            type: "json",
            data: data
          },
          method: "POST"
        }).done(
          $element.addClass("btn-success disabled").removeClass("btn-danger"),
          $element
            .find("span.icon")
            .addClass("icon-thumbs-up")
            .removeClass("icon-circular-graph icon-spin")
        );

        return null;
      });

      $(document).on("click", ".dashhead-toolbar a.dropdown-item", event => {
        const resolutionData = $(event.target).data("resolution");
        const resolutionLabel = resolutionLabels[resolutionData];
        const iconHtml = '<span class="icon icon-calendar"></span> ';

        $(".dashhead-toolbar a.dropdown-item").removeClass("active");
        $(
          '.dashhead-toolbar a.dropdown-item[data-resolution="' +
            resolutionData +
            '"]'
        ).addClass("active");
        $("#resolution").html(iconHtml + resolutionLabel);

        loadPrinterLineChart(resolutionData);
      });

      $(
        '.dashhead-toolbar a.dropdown-item[data-resolution="' +
          resolutionDefault +
          '"]'
      ).addClass("active");

      createChannel(
        { channel: "Admin::PrintersChannel", room: printerId },
        {
          received({ message }) {
            let method, rowIndex;
            const $table = $("table.commands");
            const tableData = $table.bootstrapTable("getData");
            const commandPresent =
              filter(tableData, { id: message.id }).length > 0;

            if (commandPresent) {
              method = "updateRow";
              rowIndex = findIndex(tableData, { id: message.id });
            } else {
              method = "insertRow";
              rowIndex = 0;

              if (tableData.length === 10) {
                const lastRow = last(tableData);

                $table.bootstrapTable("remove", {
                  field: "id",
                  values: [lastRow.id]
                });
              }
            }

            $table.bootstrapTable(method, { index: rowIndex, row: message });

            return null;
          }
        }
      );

      loadPrinterLineChart();

      return null;
    }
  }
});
