import $ from 'jquery';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';

import { loadAllCommandsStatusChart } from '../../shared/charts';
import createChannel from '../../shared/cable';

$(() => {
  if ($( 'body.admin-commands' ).length > 0) {
    $( 'ul.nav li a' ).removeClass( 'active' );
    $( 'li.commands a' ).addClass( 'active' );

    if ($( 'body.admin-commands-index' ).length > 0) {
      $(document).on( 'change', '#graph-resolution', () => loadAllCommandsStatusChart());
      $(document).on( 'change', '#graph-type', () => loadAllCommandsStatusChart());

      createChannel( 'Admin::CommandsChannel', {
        received({ message }) {
          let method, row_index;
          const $table = $('table.commands');
          const bs_data = $table.bootstrapTable('getData');
          const command_present = filter(bs_data, { id: message.id }).length > 0;

          if (command_present) {
            method = 'updateRow';
            row_index = findIndex(bs_data, {id: message.id});
          } else {
            method = 'insertRow';
            row_index = 0;
          }

          $table.bootstrapTable(method, { index: row_index, row: message });

          return null;
        }
      });

      return null;
    }
  }
});
