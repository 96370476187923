import $ from 'jquery';

const booleanLabelFormatter = (element) => {
  let label = 'label-success';
  const value = $(element).text().trim();

  if (value !== 'true') {
    label = 'label-danger';
  }

  return $(element).html(`<span class='label ${label}'>${value}</span>`);
};

export default booleanLabelFormatter;
