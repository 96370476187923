import $ from 'jquery';

import { loadClientJobsLineChart } from '../../shared/charts';
import { resolutionDefault, resolutionLabels } from '../../shared/constants';

$(() => {
  if ($( 'body.admin-clients' ).length > 0) {
    $( 'ul.nav li a' ).removeClass( 'active' );
    $( 'li.clients a' ).addClass( 'active' );

    if ($( 'body.admin-clients-index' ).length > 0) {
      $( 'table.clients' ).on( 'click-row.bs.table', (element, row) => {
        const client_id = row.id;
        const url = `/admin/clients/${client_id}`;

        return window.location = url;
      });

      $( document ).one( 'click', 'button.new', (event) => {
        const $element = $( event.target )
          .closest( 'button' );

        window.location = $element[0].dataset.url;
      });
    }

    if ($( 'body.admin-clients-show' ).length > 0) {
      $( document ).on( 'click', '.dashhead a.dropdown-item', (event) => {
        const resolutionData = $( event.target ).data( 'resolution' );
        const resolutionLabel = resolutionLabels[resolutionData];
        const iconHtml = '<span class="icon icon-calendar"></span> ';

        $( '.dashhead a.dropdown-item' )
          .removeClass( 'active' )
        $( '.dashhead a.dropdown-item[data-resolution="' + resolutionData + '"]' )
          .addClass( 'active' );
        $( '#graph-resolution' )
          .html( iconHtml + resolutionLabel );

        loadClientJobsLineChart(resolutionData);
      });

      $( '.dashhead a.dropdown-item[data-resolution="' + resolutionDefault + '"]' )
        .addClass( 'active' );

      $( document ).one( 'click', 'button.edit', (event) => {
        const $element = $( event.target )
          .closest( 'button' );

        window.location = $element[0].dataset.url;
      });

      loadClientJobsLineChart();

      return null;
    }
  }
});
